import { FormEvent, useCallback, useEffect, useState } from "react";
import { fetchPlayerStats, placeSellOrder, Player } from "./backend";
import { Button } from "./Button";
import { BLUE } from "./constants";

interface PlayerStatsProps {
  player: Player
}
export function PlayerStats(props: PlayerStatsProps) {
  const [goals, setGoals] = useState<number>();
  const [sellPlayerDialogOpen, setSellPlayerDialogOpen] = useState<boolean>(false);

  useEffect(() => {
    fetchPlayerStats(props.player.id).then(resp => {
      setGoals(resp.goals_for_team);
    });
  }, [props.player.id])


  const sellPlayer = useCallback(async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.target as HTMLFormElement).entries();
    const form = Object.fromEntries(formData);
    try {
      await placeSellOrder(props.player.id, parseInt(form.price as string));
      setSellPlayerDialogOpen(false);
    } catch (e) {
      console.error("TODO: Failed to create sell order");
    }
  }, [props.player.id])

  return (<div style={{
    display: 'flex',
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: 'gray',
    borderBottomStyle: 'solid',
    justifyContent: 'space-between',
    height: '10vh',
    fontSize: '14',
    paddingLeft: '1em',
    paddingRight: '1em',
    alignItems: 'center',
    backgroundColor: 'white'
  }}>
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div>Klubb: {props.player.real_team}</div>
      <div>Mål: {goals}</div>
    </div>

    <Button onClick={() => setSellPlayerDialogOpen(true)} text="Sälj Spelare"/>

    {sellPlayerDialogOpen ? <form onSubmit={sellPlayer}>
      <div style={{
        position: 'absolute',
        left: 10,
        right: 10,
        top: 90,
        // height: 400,
        backgroundColor: BLUE,
        border: '1px solid black',
        borderRadius: 30,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
        <div style={{ fontSize: 30, paddingBottom: 50, paddingTop: 50 }}>
          Lägg ut för försäljning:
        </div>
        <div style={{ fontSize: 20, paddingBottom: 50 }}>
          {props.player.first_name} {props.player.last_name}
        </div>
        <input type='number' placeholder="Pris" name='price'
          style={{
            fontSize: 22,
            marginBottom: 10,
            padding: 10,
            borderRadius: 15,
            width: '50%',
            border: '1px solid rgba(27, 31, 35, .15)'
          }}
        />
        <button
          style={{
            width: '50%',
            fontSize: 18,
            padding: 10,
            marginBottom: 10,
            borderRadius: 15,
            appearance: 'none',
            cursor: 'pointer',
            display: 'inline-block',
            boxShadow: 'rgba(27, 31, 35, .1) 0 0px 0',
            border: '1px solid rgba(27, 31, 35, .15)',
            backgroundColor: '#ff8103'
          }}
        >
          Skapa säljorder
        </button>
        <button
          type='submit'
          style={{
            width: '50%',
            fontSize: 18,
            padding: 10,
            marginBottom: 50,
            borderRadius: 15,
            appearance: 'none',
            cursor: 'pointer',
            display: 'inline-block',
            boxShadow: 'rgba(27, 31, 35, .1) 0 0px 0',
            border: '1px solid rgba(27, 31, 35, .15)',
            backgroundColor: '#ff8103',
          }}
          onClick={() => setSellPlayerDialogOpen(false)}
        >
          Avbryt
        </button>

      </div>
    </form> : <></>
    }

  </div >);
}
