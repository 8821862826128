
interface ButtonProps {
  onClick: () => void;
  text: String,
  style?: Object
}
export function Button(props: ButtonProps) {
  return (
    <button
      onClick={() => props.onClick()}
      style={{
        fontSize: 14,
        padding: 5,
        borderRadius: 10,
        appearance: 'none',
        cursor: 'pointer',
        display: 'inline-block',
        boxShadow: 'rgba(27, 31, 35, .1) 0 0px 0',
        border: '1px solid rgba(27, 31, 35, .15)',
        backgroundColor: '#ff8103',
        ...props.style
      }}
    >
      {props.text}
    </button>
  );
}
