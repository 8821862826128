import { placeBuyOrder } from "./backend";
import { Button } from "./Button";
import { Order } from "./constants";

interface PlayerOrderProps {
  order: Order,
  onBuy: () => void;
}

export function PlayerOrder(props: PlayerOrderProps) {
  const onBuyClick = async () => {
    await placeBuyOrder(props.order.player_id, props.order.price);
    props.onBuy();
  };

  return (
    <div>
      <div style={{
        display: 'flex',
        flexDirection: 'row',
        borderBottomWidth: 1,
        borderBottomColor: 'gray',
        borderBottomStyle: 'solid',
        justifyContent: 'space-between',
        padding: 10,
        color: '#538271',
        backgroundColor: 'white'
      }}>
        <div style={{
          display: 'flex',
          alignItems: 'center',
          height: '5vh',
          flexDirection: 'row',
          fontSize: 20,
          paddingLeft: '0.5em',
        }}>
          <div>
            <div>
              {props.order.player_name}
            </div>
            <div style={{ fontSize: 12 }}>
              från {props.order.team}
            </div>
          </div>
        </div>
        <div style={{
          display: 'flex',
          alignItems: 'center',
          height: '5vh',
          flexDirection: 'row',
          fontSize: 18,
        }}>
          <Button onClick={onBuyClick} text={`Köp ${props.order.price} SEK`} />
        </div>
      </div>
    </div >
  );
}
